export const DEFAULT_MESSAGE_RESPONSE_ERROR =
  'Não foi possível concluir sua solicitação'

export const PREFIX_TITLE_PAGE = 'Growk - '

export const enumOcupation = [
  // { value: 1, title: 'Root' },
  { value: 2, title: 'Administrador' },
  { value: 3, title: 'Líder' },
  { value: 4, title: 'Liderado' }
]

export const enumAssignment = [
  { value: 1, title: 'Aprendiz' },
  { value: 2, title: 'Estagiário' },
  { value: 3, title: 'Júnior' },
  { value: 6, title: 'Pleno' },
  { value: 4, title: 'Sênior' },
  { value: 5, title: 'Especialista' }
]

export const enumCategoryType = [
  { value: 1, title: 'Performance' },
  { value: 2, title: 'Conhecimento' },
  { value: 3, title: 'Comportamento' },
  { value: 4, title: 'Liderança' }
]

export const FILTER_BUTTONS = [
  { value: 1, label: 'Performance', color: '#FFA520' },
  { value: 2, label: 'Conhecimento', color: '#D81B60' },
  { value: 3, label: 'Comportamento', color: '#9C27B0' },
  { value: 4, label: 'Liderança', color: '#0063BF' }
]

export const FILTER_BUTTONS_LEADER = [
  { value: 1, label: 'Performance', color: '#FFA520' },
  { value: 3, label: 'Comportamento', color: '#9C27B0' },
  { value: 4, label: 'Liderança', color: '#0063BF' }
]

export const FILTER_BUTTONS_LED = [
  { value: 1, label: 'Performance', color: '#FFA520' },
  { value: 2, label: 'Conhecimento', color: '#D81B60' },
  { value: 3, label: 'Comportamento', color: '#9C27B0' }
]

const currentYear = new Date().getFullYear()
const january = 0
const december = 11

export const currentPeriodYear = {
  firstDay: new Date(currentYear, january, 1),
  lastDay: new Date(currentYear, december, 31)
}
