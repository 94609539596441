import React from 'react'
import { useTheme } from '@material-ui/styles'
import { Button, Tooltip, NewBoxWidget } from 'src/components'
import { IconButton, Typography, Grid, Box } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { InputMultiline } from 'src/components/Fields'
import { Form } from 'react-final-form'
import { feedbackBasicData } from 'src/utils/form/validations'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import makeStyles from './style'

export default function BasicData({
  onClose,
  payloadBasicData,
  setPayloadBasicData
}) {
  const theme = useTheme()
  const classes = makeStyles()

  const initialValues = React.useMemo(
    () => ({
      description: payloadBasicData?.description || ''
    }),
    [payloadBasicData?.description]
  )

  const onHandleSave = (values) => {
    const payload = {
      description: values.description
    }
    setPayloadBasicData(payload)
    onClose()
  }

  return (
    <Form
      onSubmit={onHandleSave}
      initialValues={initialValues}
      validate={feedbackBasicData}
      render={({ handleSubmit, form, values, valid }) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <NewBoxWidget
            title="Incluír descrição"
            options={
              <>
                <Tooltip title="Fechar">
                  <IconButton onClick={onClose}>
                    <CloseIcon htmlColor={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              </>
            }
            actions={
              <Box className={classes.actions}>
                <Typography variant="inherit">*Campos obrigatórios</Typography>
                <Button
                  label="Adicionar"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!valid}
                />
              </Box>
            }
            width={520}
          >
            <Box my={-2}>
              <form onSubmit={handleSubmit}>
                <Grid container item>
                  <Grid item xs={12}>
                    <InputMultiline
                      label="Feedback *"
                      name="description"
                      placeholder="Digite um feedback"
                      multiline="multiline"
                      rows={7}
                      onChange={(e) =>
                        form.change(
                          'description',
                          String(e?.target?.value ?? '').slice(0, 250)
                        )
                      }
                    />
                    <Typography className={classes.wordCounter}>
                      {values.description.length}/250
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </NewBoxWidget>
        </MuiPickersUtilsProvider>
      )}
    />
  )
}
