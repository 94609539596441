import React, { useState, useEffect, useCallback } from 'react'
import RoleBasedGuard from 'src/guards/RoleBasedGuard'
import useAuth from 'src/hooks/useAuth'
import { useParams, useNavigate } from 'react-router-dom'
import { IconButton, Grid } from '@material-ui/core'
import {
  Close as CloseIcon,
  AssessmentOutlined as AssessmentOutlinedIcon
} from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import {
  Button,
  HeaderBreadcrumbs,
  Helmet,
  NewBoxWidget,
  Avatar,
  Tooltip,
  Modal
} from 'src/components'
import { getImageNameColor } from 'src/utils'
import { getUserById } from 'src/services/hooks/users/useUsers'
import {
  getOneToOneById,
  putOneToOne
} from 'src/services/hooks/oneToOne/useOneToOne'
import { enumAssignment } from 'src/@enum/user'
import { viewOneToOneLeader, viewOneToOneLed } from 'src/utils/form/validations'
import { Form } from 'react-final-form'
import { Input, InputMultiline } from 'src/components/Fields'
import { useAlertContext } from 'src/context/AlertContext'
import { useLoadingContext } from 'src/context/LoadingContext'
import { messageResponse } from 'src/utils/messageResponse'
import useGlobalUseStyles from 'src/themes/globalUseStyles'
import makeStyles from './style'

function ViewOneOne() {
  const globalClasses = useGlobalUseStyles()
  const classes = makeStyles()

  const navigate = useNavigate()

  const { notifications, onCallAlert } = useAlertContext()
  const { onCloseLoading, onOpenLoading } = useLoadingContext()

  const { id: idOneToOne } = useParams()
  const { user } = useAuth()
  const currentRole = user?.occupation
  const [role] = useState([3, 4])
  const [roleLeader] = useState([3])
  const [roleLed] = useState([4])

  const [
    isOpenModalConfirmCompletedOneOne,
    setIsOpenModalConfirmCompletedOneOne
  ] = useState(false)

  const [dataUserLeader, setDataUserLeader] = useState()
  const [dataUserLed, setDataUserLed] = useState()
  const [dataOneToOne, setDataOneToOne] = useState()

  const initialValues = React.useMemo(
    () => ({
      name: dataOneToOne?.name || '',
      description: dataOneToOne?.description || '',
      annotation: dataOneToOne?.annotation || ''
    }),
    [dataOneToOne?.annotation, dataOneToOne?.description, dataOneToOne?.name]
  )

  const getDataOneToOne = useCallback(async (id) => {
    try {
      onOpenLoading()
      const data = await getOneToOneById(id)
      setDataOneToOne(data)

      const { data: dataLeader } = await getUserById(data?.fromUserId)
      setDataUserLeader(dataLeader?.data)

      const { data: dataLed } = await getUserById(data?.toUserId)
      setDataUserLed(dataLed?.data)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }, [])

  useEffect(() => {
    idOneToOne && getDataOneToOne(idOneToOne)
  }, [getDataOneToOne, idOneToOne])

  const onHandleSubmitLeader = async (values) => {
    try {
      onOpenLoading()
      //const { each, interval, repeatOn, repeatUntil } = dataOneToOne.repetition
      const repetition = dataOneToOne?.repetition
        ? {
            each: dataOneToOne?.repetition?.each,
            interval: dataOneToOne?.repetition?.interval,
            repeatOn: dataOneToOne?.repetition?.repeatOn,
            repeatUntil: dataOneToOne?.repetition?.repeatUntil
          }
        : undefined

      const payload = {
        id: dataOneToOne.id,
        name: values.name,
        description: values.description,
        annotation: dataOneToOne.annotation,
        start: dataOneToOne.start,
        finish: dataOneToOne.finish,
        repetition,
        completed: dataOneToOne.completed,
        fromUserId: dataOneToOne.fromUserId,
        toUserId: dataOneToOne.toUserId,
        customerId: dataOneToOne.customerId,
        isPrivate: false,
        isActive: true
      }
      await putOneToOne(idOneToOne, payload)
      onCallAlert({
        type: 'success',
        message: notifications.oneToOne.success.editOneToOne
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const onHandleSubmitLed = async (values) => {
    try {
      onOpenLoading()
      // const { each, interval, repeatOn, repeatUntil } = dataOneToOne.repetition
      const repetition = dataOneToOne?.repetition
        ? {
            each: dataOneToOne?.repetition?.each,
            interval: dataOneToOne?.repetition?.interval,
            repeatOn: dataOneToOne?.repetition?.repeatOn,
            repeatUntil: dataOneToOne?.repetition?.repeatUntil
          }
        : undefined

      const payload = {
        id: dataOneToOne.id,
        name: dataOneToOne.name,
        description: dataOneToOne.description,
        annotation: values.annotation,
        start: dataOneToOne.start,
        finish: dataOneToOne.finish,
        repetition,
        completed: dataOneToOne.completed,
        fromUserId: dataOneToOne.fromUserId,
        toUserId: dataOneToOne.toUserId,
        customerId: dataOneToOne.customerId,
        isPrivate: false,
        isActive: true
      }
      await putOneToOne(idOneToOne, payload)
      onCallAlert({
        type: 'success',
        message: notifications.oneToOne.success.editOneToOne
      })
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  const onHandleCompleted = async (event, values, completed) => {
    try {
      onOpenLoading()
      // const { each, interval, repeatOn, repeatUntil } = dataOneToOne?.repetition
      const repetition = dataOneToOne?.repetition
        ? {
            each: dataOneToOne?.repetition?.each,
            interval: dataOneToOne?.repetition?.interval,
            repeatOn: dataOneToOne?.repetition?.repeatOn,
            repeatUntil: dataOneToOne?.repetition?.repeatUntil
          }
        : undefined
      const payload = {
        id: dataOneToOne.id,
        name: values.name,
        description: values.description,
        start: dataOneToOne.start,
        finish: dataOneToOne.finish,
        repetition,
        completed: completed,
        fromUserId: dataOneToOne.fromUserId,
        toUserId: dataOneToOne.toUserId,
        customerId: dataOneToOne.customerId,
        isPrivate: false,
        isActive: true
      }
      await putOneToOne(idOneToOne, payload)
      onCallAlert({
        type: 'success',
        message: notifications.oneToOne.success.editOneToOne
      })
      navigate(`/metrics/user/${dataOneToOne.toUserId}`)
    } catch (error) {
      onCallAlert({ type: 'error', message: messageResponse(error?.response) })
    } finally {
      onCloseLoading()
    }
  }

  function handleMetricsUser(id) {
    navigate(`/metrics/user/${id}`)
  }

  return (
    <RoleBasedGuard hasContent roles={role}>
      <Helmet title={`1:1 - ${dataUserLed?.name} - ${dataOneToOne?.name}`} />
      <Box className={globalClasses.breadcrumb}>
        <HeaderBreadcrumbs
          icon={<AssessmentOutlinedIcon />}
          links={[
            {
              name: 'Métricas',
              href: '/metrics'
            },
            {
              name: `${dataUserLed?.name ?? ''}`,
              href: `/metrics/user/${dataUserLed?.id}`
            },
            { name: 'Impressões', href: `/metrics/user/${dataUserLed?.id}` },
            { name: '1:1' }
          ]}
        />
        <Box className={classes.boxBtnNavegation}>
          <Tooltip title="Fechar">
            <IconButton
              className={classes.boxContentBtn}
              onClick={() => handleMetricsUser(dataUserLed?.id)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box className={globalClasses.content}>
        <Box pr={2}>
          <Form
            onSubmit={(values) => onHandleSubmitLeader(values)}
            initialValues={initialValues}
            validate={viewOneToOneLeader}
            render={({ handleSubmit, values }) => (
              <>
                <Modal
                  isOpen={isOpenModalConfirmCompletedOneOne}
                  onHandleClose={() => {
                    setIsOpenModalConfirmCompletedOneOne(
                      !isOpenModalConfirmCompletedOneOne
                    )
                  }}
                  title="Concluir 1:1"
                  subTitle="Você deseja mesmo concluir o 1:1?"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={2}
                    mt={2}
                  >
                    <Button
                      label="Não, obrigado"
                      variant="dark"
                      onClick={() => {
                        setIsOpenModalConfirmCompletedOneOne(
                          !isOpenModalConfirmCompletedOneOne
                        )
                      }}
                    />
                    <Button
                      label="Sim"
                      onClick={(event) => {
                        onHandleCompleted(event, values, true)
                      }}
                    />
                  </Box>
                </Modal>
                <NewBoxWidget
                  title="1:1"
                  subtitle="Descreva as impressões do seu liderado, levando em conta o tema do seu 1:1"
                  actions={
                    <>
                      {roleLeader.includes(currentRole) && (
                        <Box display="flex" alignItems="center" gap={2}>
                          {dataOneToOne && !dataOneToOne?.completed && (
                            <Button
                              label="Finalizar"
                              variant="underline"
                              type="button"
                              onClick={() =>
                                setIsOpenModalConfirmCompletedOneOne(true)
                              }
                            />
                          )}
                          <Button
                            label="Salvar"
                            type="submit"
                            onClick={handleSubmit}
                          />
                        </Box>
                      )}
                    </>
                  }
                >
                  {dataUserLeader && (
                    <Box className={classes.boxInforUser}>
                      <Avatar
                        src={dataUserLeader?.imageProfile}
                        aria-label={dataUserLeader?.name}
                        alt={dataUserLeader?.name}
                        color={
                          !dataUserLeader?.imageProfile &&
                          getImageNameColor(dataUserLeader?.name).color
                        }
                      >
                        {getImageNameColor(dataUserLeader?.name).name}
                      </Avatar>
                      <Box display="flex" flexDirection="column" ml={1}>
                        <Typography variant="subtitle2">
                          {dataUserLeader?.name}
                        </Typography>
                        <Typography variant="body">
                          {dataUserLeader?.occupationArea} -{' '}
                          {enumAssignment[dataUserLeader?.assignment]}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <form onSubmit={handleSubmit}>
                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <Input
                          label="Assunto*"
                          name="name"
                          placeholder="Digite assunto"
                          disabled={!roleLeader.includes(currentRole)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputMultiline
                          label="Descritivo"
                          name="description"
                          placeholder="Digite descritivo"
                          rows={7}
                          multiline="multiline"
                          disabled={!roleLeader.includes(currentRole)}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </NewBoxWidget>
              </>
            )}
          />
        </Box>
        <Box>
          <Form
            onSubmit={onHandleSubmitLed}
            initialValues={initialValues}
            validate={viewOneToOneLed}
            render={({ handleSubmit }) => (
              <NewBoxWidget
                title="1:1"
                subtitle="Descreva as impressões do seu líder, levando em conta o tema do seu 1:1"
                actions={
                  <>
                    {roleLed.includes(currentRole) && (
                      <Box display="flex" alignItems="center">
                        <Button
                          label="Salvar"
                          onClick={handleSubmit}
                          type="submit"
                        />
                      </Box>
                    )}
                  </>
                }
              >
                {dataUserLed && (
                  <Box className={classes.boxInforUser}>
                    <Avatar
                      src={dataUserLed?.imageProfile}
                      aria-label={dataUserLed?.name}
                      alt={dataUserLed?.name}
                      color={
                        !dataUserLed?.imageProfile &&
                        getImageNameColor(dataUserLed?.name).color
                      }
                    >
                      {getImageNameColor(dataUserLed?.name).name}
                    </Avatar>
                    <Box display="flex" flexDirection="column" ml={1}>
                      <Typography variant="subtitle2">
                        {dataUserLed?.name}
                      </Typography>
                      <Typography variant="body">
                        {dataUserLed?.occupationArea} -{' '}
                        {enumAssignment[dataUserLed?.assignment]}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      <InputMultiline
                        label="Anotação"
                        name="annotation"
                        placeholder="Digite a anotação"
                        rows={7}
                        multiline="multiline"
                        disabled={!roleLed.includes(currentRole)}
                      />
                    </Grid>
                  </Grid>
                </form>
              </NewBoxWidget>
            )}
          />
        </Box>
      </Box>
    </RoleBasedGuard>
  )
}

export default ViewOneOne
