import React, { useState, useEffect, useCallback } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useTheme } from '@material-ui/styles'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Typography, IconButton, Box } from '@material-ui/core'
import {
  Add as AddIcon,
  Close as CloseIcon,
  FolderOutlined as FolderOutlinedIcon
} from '@material-ui/icons'
import {
  Tooltip,
  NewBoxWidget,
  CircularProgressWithLabel
} from 'src/components'
import { postDevelopmentPlanSearch } from 'src/services/hooks/developmentPlan/useDevelopmentPlan'
import { getUserById } from 'src/services/hooks/users/useUsers'
import DetailsPDI from './DetailsPDI'
import makeStyles from './style'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function PDI({ userId, onClose }) {
  const theme = useTheme()
  const classes = makeStyles()
  const query = useQuery()
  const queryUserId = query.get('user')
  const navigate = useNavigate()
  const { user } = useAuth()
  const { id: paramUserId = queryUserId } = useParams()
  const currentUser = user?.id
  const currentCustomerId = user?.customerId
  const currentRole = user?.occupation
  const [roleAddPDI] = useState([3, 4])

  const [dataUser, setDataUser] = useState()
  const [data, setData] = useState([{}])

  const [isLoading, setIsLoading] = useState(false)
  const [isSelected, setIsSelected] = useState(0)
  const [openDetailsPDI, setOpenDetailsPDI] = useState(false)
  const [enableAddPdiLed, setEnableAddPdiLed] = useState(false)
  const [enableAddPdiLeader, setEnableAddPdiLeader] = useState(false)

  function onOpenDetailsPDI(id) {
    setIsSelected(id)
    setOpenDetailsPDI(true)
  }

  function onCloseDetailsPDI() {
    setIsSelected(0)
    setOpenDetailsPDI(false)
  }

  const getDataUser = useCallback(async (userParamId) => {
    try {
      const { data } = await getUserById(userParamId)
      const addPdiLed = currentRole === 4 && data?.data?.occupation === 4
      const addPdiLeader =
        currentRole === 3 &&
        (data?.data?.occupation === 4 || data?.data?.occupation === 3)
      setEnableAddPdiLed(addPdiLed)
      setEnableAddPdiLeader(addPdiLeader)
      setDataUser(data?.data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const getDevelopmentPlanSearch = useCallback(
    async (customerId, userId, userParamId) => {
      try {
        setIsLoading(true)
        const payload = {
          customerId,
          toUserId: userParamId
          // fromUserId: userId
        }
        const response = await postDevelopmentPlanSearch(payload)
        const developmentPlans = response?.filter(
          (item) => item.status !== 3 && item.status !== 4
        )
        setData(developmentPlans)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    const queryIdPDI = query.get('pdi')
    paramUserId && getDataUser(paramUserId)
    queryIdPDI && onOpenDetailsPDI(queryIdPDI)

    currentCustomerId &&
      currentUser &&
      paramUserId &&
      getDevelopmentPlanSearch(currentCustomerId, currentUser, paramUserId)
  }, [
    currentCustomerId,
    currentUser,
    getDataUser,
    getDevelopmentPlanSearch,
    paramUserId,
    query
  ])

  function onHandleArchive(id) {
    navigate(`/development-plan/archive/${id}`)
  }

  const getColorStatusSelected = (status) => {
    return status === 4
      ? '#787878'
      : status === 6
      ? '#E32929'
      : theme.palette.primary.main
  }

  return (
    <>
      <NewBoxWidget
        title="PDI"
        options={
          <>
            <Tooltip title="Arquivo">
              <IconButton
                onClick={() => onHandleArchive(userId)}
                className={classes.iconButton}
              >
                <FolderOutlinedIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Fechar">
              <IconButton onClick={onClose} className={classes.iconButton}>
                <CloseIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          </>
        }
        width={450}
        height="100%"
      >
        <>
          {roleAddPDI.includes(currentRole) && (
            <>
              {enableAddPdiLed && !enableAddPdiLeader && (
                <Box
                  className={classes.addPdi}
                  onClick={() => navigate(`/development-plan/add/${userId}`)}
                >
                  Adicionar PDI
                  <IconButton
                    type="button"
                    onClick={() => navigate(`/development-plan/add/${userId}`)}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
              {enableAddPdiLeader && !enableAddPdiLed && (
                <Box
                  className={classes.addPdi}
                  onClick={() => navigate(`/development-plan/add/${userId}`)}
                >
                  Adicionar PDI
                  <IconButton
                    type="button"
                    onClick={() => navigate(`/development-plan/add/${userId}`)}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
            </>
          )}
        </>
        {!isLoading && (
          <>
            {data?.map((item, index) => (
              <Box className={classes.content} key={index}>
                <Box
                  key={index}
                  className={
                    isSelected === item.id
                      ? classes.buttonPlanSelected
                      : classes.buttonPlan
                  }
                  style={{
                    borderColor:
                      isSelected === item.id &&
                      getColorStatusSelected(item.status)
                  }}
                  onClick={() => {
                    onOpenDetailsPDI(item.id)
                  }}
                >
                  <Box className={classes.buttonDescription}>
                    <Typography variant="h6">{item.name}</Typography>
                    <Typography variant="caption">
                      {item.description}
                    </Typography>
                  </Box>
                  <Box className={classes.planProgress}>
                    <CircularProgressWithLabel
                      value={item.progress}
                      status={item.status}
                      color={item.status !== 6 ? 'success' : 'error'}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        )}
      </NewBoxWidget>
      {openDetailsPDI && (
        <DetailsPDI
          onClose={onCloseDetailsPDI}
          idDevPlan={isSelected}
          userId={userId}
          getDevelopmentPlanSearch={getDevelopmentPlanSearch}
          userRole={dataUser?.occupation}
        />
      )}
    </>
  )
}

export default PDI
